import { Component, ViewContainerRef } from '@angular/core';
import { ViewsService, View, LocalesService, BrowserService, ButtonContainerComponent, TurboApiCallerService } from 'turbogui-angular';
import { ProductsViewComponent } from '../../../view/views/products-view/products-view.component';
import { CatalogsViewComponent } from '../../../view/views/catalogs-view/catalogs-view.component';
import { OrdersMenuViewComponent } from '../orders-menu-view/orders-menu-view.component';
import { UserModel } from 'src/main/model/models/user.model';
import { CommonModule } from '@angular/common';


/**
 * Home view
 */
@Component({
    selector: 'app-home-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent],
    providers: [],
    templateUrl: './home-view.component.html',
    animations: [],
    styleUrls: ['./home-view.component.scss']
})


export class HomeViewComponent extends View {


    /**
     * Declare a reference to the view component so it can be used on the html part
     */
    productsViewComponent = ProductsViewComponent;


    /**
     * Declare a reference to the view component so it can be used on the html part
     */
    catalogsViewComponent = CatalogsViewComponent;


    /**
     * Declare a reference to the view component so it can be used on the html part
     */
    ordersViewComponent = OrdersMenuViewComponent;


    constructor(public ls: LocalesService,
                public viewsService: ViewsService,
                public userModel:UserModel,
                public apiService: TurboApiCallerService,
                public browserService: BrowserService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, null);        
    }


    openTransmitanciaAppLink(){
        
        this.apiService.createUserToken({useCount: 1}).then(token => {
                    
            this.browserService.goToUrl('https://transmitancia.unitest.site/Home/Login?token=' + token, true);
        });
    }
}
