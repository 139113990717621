import { Component, ViewContainerRef } from '@angular/core';
import { ViewsService, View, LocalesService, ButtonContainerComponent, TurboApiCallerService } from 'turbogui-angular';
import { UserModel } from 'src/main/model/models/user.model';
import { OrdersNewViewComponent } from '../orders-new-order-view/orders-new-order-view.component';
import { OrdersWebListViewComponent } from '../orders-web-list-view/orders-web-list-view.component';
import { OrdersHistoryViewComponent } from '../orders-history-view/orders-history-view.component';
import { ExpansionRoutesViewComponent } from '../expansion-routes-view/expansion-routes-view.component';
import { CommonModule } from '@angular/common';


/**
 * Orders view
 */
@Component({
    selector: 'app-orders-menu-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent],
    providers: [],
    templateUrl: './orders-menu-view.component.html',
    animations: [],
    styleUrls: ['./orders-menu-view.component.scss']
})


export class OrdersMenuViewComponent extends View {


    constructor(public ls: LocalesService,
                public viewsService: ViewsService,
                public apiService: TurboApiCallerService,
                public userModel: UserModel,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, null);
    }
        
    loadOrdersView(){
        
        this.viewsService.pushView(OrdersNewViewComponent);
    }
    
    loadWebListView(){
        
        this.viewsService.pushView(OrdersWebListViewComponent);
    }
    
    loadHistoryView(){
        
        this.viewsService.pushView(OrdersHistoryViewComponent);
    }
    
    loadExpansionRoutesView(){
        
        this.viewsService.pushView(ExpansionRoutesViewComponent);
    }
}
